import Header from "../Header";
import HomeBanner from "../HomeBanner";
import About from '../About';
import Services from '../Services';
import Resume from '../Resume';
import WhatIDo from '../WhatIDo';
import Education from '../Education';
import Testimonial from '../Testimonial';
import Blog from '../Blog';
import ContactUs from '../ContactUs';
import Footer from '../Footer';

/*--------------------
* Building PGupta
----------------------*/

export default function PGupta() {
    // $('body')[0].classList.add('dark-bg');
    return (
    <>
            <Header />
            <main>
                <HomeBanner />
                <About />
                <WhatIDo />
                <Services />
                <Resume />
                <Education />
                <Blog />
                <Testimonial />
                <ContactUs />
            </main>
            <Footer />
        </>
    )
}
