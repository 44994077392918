import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import 'swiper/swiper-bundle.css';
import '../assets/css/blogs.css';

// Function to extract the first image URL from HTML content
function extractFirstImageUrl(html) {
    const div = document.createElement('div');
    div.innerHTML = html;
    const img = div.querySelector('img');
    return img ? img.src : null;
}

// Function to truncate HTML content
function truncateHTML(html, maxLength) {
    const div = document.createElement('div');
    div.innerHTML = html;
    const text = div.textContent || div.innerText || '';
    return text.length > maxLength ? text.slice(0, maxLength) + '...' : text;
}

export default function Blog() {
    const [blogs, setBlogs] = useState([]);
    const [theme, setTheme] = useState(localStorage.getItem('theme') || 'dark');

    useEffect(() => {
        const fetchBlogs = async () => {
            const RSS_URL = "https://medium.com/feed/@prashantcodecraft";
            const API_URL = `https://api.rss2json.com/v1/api.json?rss_url=${encodeURIComponent(RSS_URL)}`;

            try {
                const response = await axios.get(API_URL);
                const processedBlogs = response.data.items.map(blog => ({
                    ...blog,
                    thumbnail: blog.thumbnail || extractFirstImageUrl(blog.content)
                }));
                setBlogs(processedBlogs);
            } catch (error) {
                console.error('Error fetching blog posts:', error);
            }
        };

        fetchBlogs();

        const handleStorageChange = () => {
            setTheme(localStorage.getItem('theme') || 'dark');
        };

        window.addEventListener('storage', handleStorageChange);

        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, []);

    const isDarkMode = theme !== 'light';

    return (
        <section id="blog" data-scroll-data="4" className={`section ${isDarkMode ? 'dark-bg-1' : 'light-bg-1'}`}>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6 text-center">
                        <div className="section-title">
                            <h2><span>Latest Blog</span></h2>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center mb-4">
                    <div className="col-auto">
                        <a href="https://blog.prashantcodecraft.com/" target="_blank" rel="noopener noreferrer" className="btn btn-primary">
                            ALL BLOGS
                        </a>
                    </div>
                </div>
                {blogs.length === 0 ? (
                    <p>No blog posts found.</p>
                ) : (
                    <Swiper
                        spaceBetween={30}
                        slidesPerView={2}
                        loop={true}
                        modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                        pagination={{ clickable: true }}
                        autoplay={{
                            delay: 2000,
                            disableOnInteraction: false,
                        }}
                        breakpoints={{
                            0: {
                                slidesPerView: 1,
                            },
                            992: {
                                slidesPerView: 2,
                            },
                        }}
                    >
                        {blogs.map((post, index) => (
                            <SwiperSlide key={post.guid}>
                                <div className="blog-post">
                                    <h3>
                                        {post.title}
                                        <span className="medium-icon px-2">
                                            <i className="bi bi-medium" aria-hidden="true"></i>
                                        </span>
                                    </h3>
                                    <p className="blog-date">
                                        {new Date(post.pubDate).toLocaleDateString('en-US', {
                                            year: 'numeric',
                                            month: 'long',
                                            day: 'numeric'
                                        })}
                                    </p>
                                    <div className="row">
                                        <div className="col-md-6">
                                            {post.thumbnail && (
                                                <div className="blog-thumbnail mb-3">
                                                    <img
                                                        src={post.thumbnail}
                                                        alt={post.title}
                                                        style={{ objectFit: 'contain', width: '100%', height: '200px' }}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-md-6">
                                            <div dangerouslySetInnerHTML={{ __html: truncateHTML(post.description, 150) }} />
                                            <a className="btn btn-primary mt-3" href={post.link} target="_blank" rel="noopener noreferrer">
                                                Read More <i className="bi bi-medium" aria-hidden="true"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                )}
            </div>
        </section>
    );
}