import { useState } from "react";
import { useTheme } from "./ThemeProvider";
import { Link as RouterLink } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';

/*--------------------
* Header Menu
----------------------*/

function Menu(props){
    return (
        <li>
            <ScrollLink 
                to={props.scrollLink}
                smooth={true}
                duration={500}
                offset={props.offset}
                className={props.className}
                data-scroll-nav={props.id}
                onClick={props.onClick}
            >
                <span>{props.name}</span>
            </ScrollLink>
        </li>
    );
}

/*--------------------
* Header
----------------------*/

export default function Header() {
    const [isOpenMenu, setIsOpenMenu] = useState(false);
    const { toggleTheme } = useTheme();

    const header_menus = [
        { id:'0', name:'Home', scrollLink:'home', offset: 0 },
        { id:'1', name:'About Me', scrollLink:'about', offset: 0 },
        { id:'2', name:'Services', scrollLink:'services', offset: 0 },
        { id:'3', name:'Experience', scrollLink:'resume', offset: 0 },
        { id:'4', name:'Blog', scrollLink:'blog', offset: 0 },
        { id:'5', name:'Contact', scrollLink:'contactus', offset: 0 },
        { id:'6', name:'Download CV', externalLink:'https://prashantcodecraft.s3.amazonaws.com/CVPrashantGupta.pdf' }
    ];

    const MenuLink = ({ item, onClick }) => {
        if (item.externalLink) {
            return (
                <li>
                    <a href={item.externalLink} target="_blank" rel="noopener noreferrer" className="nav-link" onClick={onClick}>
                        <span>{item.name}</span>
                    </a>
                </li>
            );
        }
        return (
            <Menu 
                className="nav-link" 
                key={item.id} 
                id={item.id} 
                name={item.name} 
                scrollLink={item.scrollLink} 
                offset={item.offset}
                onClick={onClick}
            />
        );
    };

    const toggleMenu = () => {
        setIsOpenMenu(!isOpenMenu);
    };

    const closeMenu = () => {
        setIsOpenMenu(false);
    };

    return (
        <>
            <header className="main-header">
                <nav className="navbar header-nav header-white header-transparent navbar-expand-lg one-page-nav">
                    <div className="container">
                        <RouterLink className="navbar-brand" to="/">Prashant Gupta</RouterLink>
                        <button className="navbar-toggler" type="button" onClick={toggleMenu} aria-label="Toggle navigation">
                            <span></span>
                            <span></span>
                            <span></span>
                        </button>
                        <div className={`collapse navbar-collapse justify-content-end ${isOpenMenu ? 'show' : ''}`} id="navbar-collapse-toggle">
                            <ul className="navbar-nav lg:ml-auto">
                                {
                                    header_menus.map((item) => (
                                        <MenuLink item={item} key={item.id} onClick={closeMenu} />
                                    ))
                                }
                            </ul>
                        </div>
                        <button onClick={toggleTheme} className="theme-toggle-btn">
                            <i className="bi bi-sun"></i>
                            <i className="bi bi-moon-stars"></i>  
                        </button>
                    </div>
                </nav>
            </header>
        </>
    )
}
