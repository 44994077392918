import React from 'react';
import TechnologyGroup from './TechnologyGroup';
import { useTheme } from "./ThemeProvider";

const technologyGroups = [
    {
        name: 'Programming & Frameworks',
        technologies: ['Java', 'Angular', 'React', 'Micronaut', 'Spring Boot'],
        icon: 'bi bi-code-slash'
    },
    {
        name: 'Database',
        technologies: ['SQL/NoSQL', 'MySQL', 'MongoDB', 'DynamoDB', 'Oracle 12c', 'PostgreSQL'],
        icon: 'bi bi-database'
    },
    {
        name: 'Messaging & Integration',
        technologies: ['Kafka', 'Active MQ', 'RabbitMQ', 'gRPC', 'SQS', 'SNS'],
        icon: 'bi bi-envelope'
    },
    {
        name: 'Architecture Patterns',
        technologies: ['Event-Driven', 'Microservices', 'Service-Oriented', 'Serverless', 'Monolithic', 'Pipeline'],
        icon: 'bi bi-diagram-3'
    },
    {
        name: 'AWS Cloud & Infrastructure',
        technologies: ['S3', 'Route53', 'CloudFront', 'IAM', 'VPC', 'EC2', 'Lambda', 'API Gateway', 'DynamoDB', 'CloudFormation', 'CloudWatch', 'X-Ray', 'Step Functions'],
        icon: 'bi bi-cloud'
    },
    {
        name: 'Testing & Quality',
        technologies: ['JUnit', 'Mockito', 'ArchUnit', 'SonarQube'],
        icon: 'bi bi-bug'
    },
    {
        name: 'CI/CD & DevOps',
        technologies: ['Jenkins', 'AWS CodePipeline', 'GitHub Actions'],
        icon: 'bi bi-gear'
    }
];

export default function Services() {
    const { theme } = useTheme();

    const isDarkMode = theme !== 'light';

    return (
        <section id="services" className="section section-resume">
            <div className="text-placeholder">
                <h5>Skills</h5>
            </div>
            <div className="container">
                <h2 className="section-title text-center mb-5">Technologies and Skills</h2>
                <div className="row justify-content-center">
                    {technologyGroups.map((group, index) => (
                        <div key={index} className="col-md-6 col-lg-4 mb-4">
                            <TechnologyGroup 
                                name={group.name} 
                                technologies={group.technologies} 
                                icon={group.icon}
                                isDarkMode={isDarkMode}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}