export default function Resume() {
    const resume = [
        {
            'id': 'rsm0',
            'company': 'Kune India Ltd (A.K.A. Atomise)',
            'company_url': 'https://www.linkedin.com/company/atomise-limited/',
            'city': 'Gurugram, India',
            'start_date': 'July, 2023',
            'end_date': 'Present',
            'positions': [
                {
                    'title': 'Director of Engineering',
                    'description': 'Leading a team of engineers to develop innovative software solutions. Overseeing multiple projects, implementing best practices, and driving technological advancements to meet business objectives.'
                }
            ]
        },
        {
            'id': 'rsm1',
            'company': 'Bravura Solutions',
            'company_url': 'https://www.linkedin.com/school/bravura-solutions',
            'city': 'Gurugram, India',
            'start_date': 'May, 2016',
            'end_date': 'July, 2023',
            'positions': [
                {
                    'title': 'Development Manager',
                    'description': 'Managed cross-functional development teams, oversaw project lifecycles, and ensured timely delivery of high-quality software products. Implemented Agile methodologies and fostered a culture of continuous improvement.'
                }
            ]
        },
        {
            'id': 'rsm2',
            'company': '',
            'company_url': '#',
            'city': 'Chandigarh, India',
            'start_date': 'Feb, 2014',
            'end_date': 'May, 2016',
            'positions': [
                {
                    'title': 'Professional Freelancer',
                    'description': 'Provided expert software development services to various clients, specializing in web and mobile application development. Managed multiple projects simultaneously while maintaining high client satisfaction.'
                }
            ]
        },
        {
            'id': 'rsm3',
            'company': 'Infosys Limited',
            'company_url': 'https://www.linkedin.com/company/infosys',
            'city': 'Chandigarh, India',
            'start_date': 'Feb, 2011',
            'end_date': 'Feb, 2014',
            'positions': [
                {
                    'title': 'Senior System Engineer',
                    'description': 'Designed and implemented complex software systems, focusing on scalability and performance optimization. Mentored junior engineers and contributed to the development of internal tools and frameworks.'
                }
            ]
        },
        {
            'id': 'rsm4',
            'company': 'PacketVideo',
            'company_url': 'https://www.linkedin.com/company/packetvideo/',
            'city': 'Chandigarh, India',
            'start_date': 'Oct, 2010',
            'end_date': 'Dec, 2010',
            'positions': [
                {
                    'title': 'Software Developer',
                    'description': 'Contributed to the development of multimedia streaming solutions for mobile devices. Collaborated with cross-functional teams to integrate new features and improve system performance.'
                }
            ]
        },
        {
            'id': 'rsm5',
            'company': 'A-1 Technology',
            'company_url': 'https://www.linkedin.com/company/a-1-technology/',
            'city': 'Chandigarh, India',
            'start_date': 'Jan, 2010',
            'end_date': 'Oct, 2010',
            'positions': [
                {
                    'title': 'Software Developer',
                    'description': 'Developed and maintained web applications using various programming languages and frameworks. Participated in code reviews, bug fixing, and collaborated with the QA team to ensure software quality.'
                }
            ]
        }
    ];

    return (
        <>
            <section id="resume" className="section section-resume dark-bg-1">
                <div className="text-placeholder">
                    <h5>Experience</h5>
                </div>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-6 text-center">
                            <div className="section-title">     
                                <h2><span>My Experience</span></h2>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center pt-3">
                        <div className="col-lg-10">
                            <div className="resume-box">
                                <ul>
                                    {resume.map((company) => (
                                        <li className="row g-0" key={company.id}>
                                            <div className="col-sm-4 col-xl-3 justify-content-center">
                                                <h5><a href={company.company_url} target="_blank" rel="noopener noreferrer">{company.company}</a></h5>
                                                <h6>{company.start_date} – {company.end_date}</h6>
                                                <br></br>
                                                <span>{company.city}</span>
                                            </div>
                                            <div className="col-sm-8 col-xl-9 ps-xl-4">
                                                {company.positions.map((position, index) => (
                                                    <div key={index} className="mb-3">
                                                        <h5>{position.title}</h5>
                                                        <p>{position.description}</p>
                                                    </div>
                                                ))}
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}