import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import 'swiper/swiper-bundle.css';

export default function Testimonial() {
    const [testimonials, setTestimonials] = useState([]);

    useEffect(() => {
        const fetchTestimonials = async () => {
            try {
                const response = await axios.get('https://data.accentapi.com/feed/25454757.json?nocache=1724848203379');
                const receivedTestimonials = response.data.posts.filter(testimonial => testimonial.received === "true");
                setTestimonials(receivedTestimonials);
            } catch (error) {
                console.error('Error fetching testimonials:', error);
            }
        };

        fetchTestimonials();
    }, []);

    return (
        <section className="section testimonials-section">
            <div className="text-placeholder">
                <h5>testimonials</h5>
            </div>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6 text-center">
                        <div className="section-title">
                            <h2><span>Testimonials</span></h2>
                        </div>
                    </div>
                </div>
                {testimonials.length > 0 ? (
                    <Swiper 
                    spaceBetween={30} 
                    slidesPerView={2} 
                    loop={true}
                    modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                    pagination={{ clickable: true }}
                    autoplay={{
                        delay: 1000,
                        disableOnInteraction: false,
                    }}
                    breakpoints={{
                         0: {
                            slidesPerView: 1,
                        },
                        992: {
                            slidesPerView: 2,
                        },
                    }}
                >
                    {testimonials.map((val) => (
                        <SwiperSlide key={val.id}>
                            <div className="testimonial-col">
                                <div className="d-flex">
                                    <div className="img">
                                        <img 
                                            src={val.profile_image} 
                                            alt={val.profile_name} 
                                            className="rounded-circle"
                                        />
                                    </div>
                                    <div className="col">
                                        <div className="tc-info">
                                            <h6>
                                            <a 
                                                href={val.profile_link} 
                                                target="_blank" 
                                                rel="noopener noreferrer"
                                                className="testimonial-name-link"
                                            >
                                                {val.profile_name}
                                                <i className="bi bi-linkedin px-2 color-primary" aria-hidden="true"></i>
                                            </a>
                                            </h6>
                                            <span>{val.profile_position}</span>
                                            <p>
                                                <i className="fas fa-quote-left quote-icon-left"></i>
                                                {val.description}
                                                <i className="fas fa-quote-right quote-icon-right"></i>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
                ) : (
                    <p className="text-center">No testimonials available at the moment.</p>
                )}
            </div>
        </section>
    );
}
