import React from "react";
import "../assets/css/technologygroup.css";

export default function TechnologyGroup({
  name,
  technologies,
  icon,
  isDarkMode,
}) {
  return (
    <div className="feature-box-1">
      <div className="icon hexagon">
        <i className={icon}></i>
      </div>
      <div className="feature-content">
        <h5>{name}</h5>
        <div className={`technology-chips text-center ${isDarkMode ? 'dark' : 'light'}`}>
          {technologies.map((tech, index) => (
            <span key={index} className="chip d-inline-block">
              {tech}
            </span>
          ))}
        </div>
      </div>
    </div>
  );
}
