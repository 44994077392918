export default function Education() {
  const education = [
    {
      id: "rsm0",
      title: "B. Tech Computer Science Engineering",
      institute: "RIMT INSTITUTE OF ENGINEERING COLLEGE",
      city: "GOBINDGARH, PUNJAB",
      end_date: "Jun, 2010",
      grade: "75.81%",
    },
    {
      id: "rsm1",
      title: "School - 12 Standard",
      institute: "PSEB BOARD",
      city: "PATIALA, PUNJAB",
      end_date: "Mar, 2006",
      grade: "73.60%",
    },
    {
      id: "rsm2",
      title: "School - 10 Standard",
      institute: "CBSE BOARD",
      city: "PATIALA, PUNJAB",
      end_date: "Mar, 2004",
      grade: "74%",
    },
  ];

  const certification = [
    {
      id: "csf0",
      title: "AWS Certified Solutions Architect - Associate",
      start_date: "November 6, 2024",
      end_date: "November 6, 2027",
    },
  ];

  return (
    <>
      <section className="section section-resume">
        <div className="text-placeholder">
          <h5>EDUCATION</h5>
        </div>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6 text-center">
              <div className="section-title">
                <h2>
                  <span>CERTIFICATION</span>
                </h2>
              </div>
            </div>
          </div>
          <div className="row justify-content-center pt-3">
            <div className="col-lg-10">
              <div className="resume-box">
                <ul>
                  {certification.map((institute) => (
                    <li className="row g-0" key={institute.id}>
                      <div className="row col-sm-8 col-xl-9 align-content-center">
                        <div className="col-sm-6 col-xl-5 justify-content-center">
                          <h6>Issue Date : {institute.start_date}</h6>
                          <h6>Expiry Date : {institute.end_date}</h6>
                        </div>
                        <div className="col-sm-6 col-xl-7 justify-content-center">
                          <h5>{institute.title}</h5>
                          <br></br>
                        </div>
                      </div>
                      <div className="col-sm-4 col-xl-3 justify-content-center">
                        <a
                          href="https://www.credly.com/badges/ba371b55-16aa-4c37-9274-f99a680a9f38/public_url"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src="img/aws-certified.png"
                            alt="Certification Badge"
                            style={{ width: "100px", height: "100px" }}
                          />
                        </a>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div className="row justify-content-center pt-5">
            <div className="col-lg-6 text-center">
              <div className="section-title">
                <h2>
                  <span>EDUCATION AND QUALIFICATIONS</span>
                </h2>
              </div>
            </div>
          </div>
          <div className="row justify-content-center pt-3">
            <div className="col-lg-10">
              <div className="resume-box">
                <ul>
                  {education.map((institute) => (
                    <li className="row g-0" key={institute.id}>
                      <div className="col-sm-4 col-xl-3 justify-content-center">
                        <h6>{institute.end_date}</h6>
                      </div>
                      <div className="col-sm-8 col-xl-9 ps-xl-4">
                        <h5>{institute.title}</h5>
                        <h5>{institute.grade}</h5>
                        <span>{institute.institute}</span>
                        <br></br>
                        <span>{institute.city}</span>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
