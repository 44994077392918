export default function WhatIDo() {
    const services = [
        {'id': 'ser0', 'icon': 'bi bi-laptop', 'title': 'Full Stack Development', 'description': 'Skilled in both front-end and back-end technologies, creating modern and efficient web applications for clients of all sizes.'},
        {'id': 'ser1', 'icon': 'bi bi-code-square', 'title': 'Java Backend with Micronaut', 'description': 'Expertise in building robust and scalable backend services using Java and Micronaut framework, with a focus on serverless deployment for cost-efficiency.'},
        {'id': 'ser2', 'icon': 'bi bi-front', 'title': 'Angular & React Development', 'description': 'Proficient in creating dynamic and responsive user interfaces using both Angular and React frameworks, ensuring optimal front-end performance and user experience.'},
        {'id': 'ser3', 'icon': 'bi bi-cloud-upload', 'title': 'DevOps & CI/CD', 'description': 'Experience in implementing CI/CD pipelines using AWS CodeBuild & CodeDeploy or Jenkins, streamlining the development and deployment process.'},
        {'id': 'ser4', 'icon': 'bi bi-database', 'title': 'Database Management', 'description': 'Skilled in both SQL and NoSQL database systems, designing and optimizing data structures for efficient storage and retrieval.'},
        {'id': 'ser5', 'icon': 'bi bi-cloud-check', 'title': 'Serverless Architecture', 'description': 'Expertise in designing and deploying serverless applications, leveraging cloud services to reduce operational costs and improve scalability.'},
    ];

    return (
        <>
            <section id="services" data-scroll-data="2" className="section services-section">
                <div className="text-placeholder">
                    <h5>WHAT</h5>
                </div>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-6 text-center">
                            <div className="section-title">
                                <h2><span>What I Do</span></h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {
                            services.map((val) => {
                                return (
                                    <div className="col-sm-6 col-lg-4 my-3" key={val.id}>
                                        <div className="feature-box-1">
                                            <div className="icon hexagon">
                                                <i className={val.icon}></i>
                                            </div>
                                            <div className="feature-content">
                                                <h5>{val.title}</h5>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })
                        }
                    </div>
                </div>
            </section>
        </>
    );
}