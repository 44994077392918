import React, { useState, useRef } from 'react';
import emailjs from '@emailjs/browser';

export default function ContactUs() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [status, setStatus] = useState(null); // null, 'success', or 'error'
    const form = useRef();

    const mailformat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

    const validate = () => {
        let formErrors = {};
        let valid = true;

        if (!name) {
            formErrors.name = 'Your Name is Required';
            valid = false;
        }

        if (!email) {
            formErrors.email = 'Please Enter Email';
            valid = false;
        } else if (!email.match(mailformat)) {
            formErrors.email = 'Please Enter Valid Email';
            valid = false;
        }

        if (!subject) {
            formErrors.subject = 'Subject is Required';
            valid = false;
        }

        if (!message) {
            formErrors.message = 'Please Describe your thoughts';
            valid = false;
        }

        setErrors(formErrors);
        return valid;
    };
    
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validate()) {
            return;
        }

        setIsLoading(true);
        setStatus(null);

        emailjs.sendForm('service_tvxom9u', 'template_q2ukcin', form.current, 'uYOvy2EPzajfVGCyX')
            .then((result) => {
                console.log(result.text);
                setStatus('success');
                setName('');
                setEmail('');
                setSubject('');
                setMessage('');
            }, (error) => {
                console.log(error.text);
                setStatus('error');
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return (
        <>
            <section id="contactus" data-scroll-data="5" className="section dark-bg-1 contactus-section">
                <div className="text-placeholder">
                    <h5>Contact</h5>
                </div>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-6 text-center">
                            <div className="section-title">
                                <h2><span>Contact Me</span></h2>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-lg-4 my-3">
                            <div className="contact-info">
                                <div className="contact-name">
                                    <div className="sm-title">
                                        <h3>Email</h3>
                                    </div>
                                    <p>prashantcodecraft@gmail.com<br />cprashantgupta@gmail.com</p>
                                </div>
                                <div className="contact-name">
                                    <div className="sm-title">
                                        <h3>Address</h3>
                                    </div>
                                    <p>Sector 65, Gurugram, Harayana, India</p>
                                </div>
                                <div className="contact-name">
                                    <div className="sm-title">
                                        <h3>Phone</h3>
                                    </div>
                                    <p>+91 98883 36280</p>
                                </div>
                                <div className="social-share nav">
                                    {/* <a href="https://www.facebook.com/PrashantCodeCraft" target="_blank" rel="noopener noreferrer">
                                        <i className="bi bi-facebook"></i>
                                    </a>
                                    <a href="https://x.com/PGCodeCraft" target="_blank" rel="noopener noreferrer">
                                        <i className="bi bi-twitter"></i>
                                    </a>
                                    <a href="https://www.instagram.com/prashantcodecraft/" target="_blank" rel="noopener noreferrer">
                                        <i className="bi bi-instagram"></i>
                                    </a> */}
                                    <a href="https://www.linkedin.com/in/prashantcodecraft/" target="_blank" rel="noopener noreferrer">
                                        <i className="bi bi-linkedin"></i>
                                    </a>
                                    <a href="https://github.com/prashantcodecraft" target="_blank" rel="noopener noreferrer">
                                        <i className="bi bi-github"></i>
                                    </a>
                                    <a href="https://medium.com/@prashantcodecraft" target="_blank" rel="noopener noreferrer">
                                        <i className="bi bi-medium"></i>
                                    </a>
                                    {/* <a href="https://www.tripadvisor.com/Profile/PrashantCodeCraft" target="_blank" rel="noopener noreferrer">
                                        <i className="fa fa-tripadvisor"></i>
                                    </a> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8 my-3">
                            <div className="contact-form">
                                <div className="sm-title">
                                    <h3>Get In Touch</h3>
                                </div>
                                <form id="contact-form" ref={form} onSubmit={handleSubmit}>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <input name="name" id="name" placeholder="Name *" className="form-control" type="text" value={name} onChange={(e) => setName(e.target.value)} />
                                                {errors.name && <span className="text-danger">{errors.name}</span>}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <input name="email" id="email" placeholder="Email *" className="form-control" type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                                                {errors.email && <span className="text-danger">{errors.email}</span>}
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group">
                                                <input name="subject" id="subject" placeholder="Subject *" className="form-control" type="text" value={subject} onChange={(e) => setSubject(e.target.value)} />
                                                {errors.subject && <span className="text-danger">{errors.subject}</span>}
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <textarea name="message" id="message" placeholder="Your message *" rows="3" className="form-control" value={message} onChange={(e) => setMessage(e.target.value)}></textarea>
                                                {errors.message && <span className="text-danger">{errors.message}</span>}
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="send">
                                                <button 
                                                    className="px-btn px-btn-theme" 
                                                    type="submit" 
                                                    disabled={isLoading}
                                                >
                                                    {isLoading ? 'Sending...' : 'Send Message'}
                                                </button>
                                            </div>
                                            {status === 'success' && (
                                                <div className="text-success mt-3">Message sent successfully!</div>
                                            )}
                                            {status === 'error' && (
                                                <div className="text-danger mt-3">Failed to send message. Please try again.</div>
                                            )}
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}