import { Link } from 'react-router-dom';

export default function Footer() {
    const footer_menu = [
        {'id': 'footer0', 'scroll_link': 'https://www.linkedin.com/in/prashantcodecraft', 'icon': 'bi bi-linkedin'},    
        {'id': 'footer1', 'scroll_link': 'https://github.com/prashantcodecraft', 'icon': 'bi bi-github'},
        {'id': 'footer2', 'scroll_link': 'https://medium.com/@prashantcodecraft', 'icon': 'bi bi-medium'},
        {'id': 'footer3', 'scroll_link': 'https://www.tripadvisor.com/Profile/PrashantCodeCraft', 'icon': 'fa fa-tripadvisor'},
        // {'id': 'footer4', 'scroll_link': 'https://www.facebook.com/PrashantCodeCraft', 'icon': 'bi bi-facebook'},    
        {'id': 'footer5', 'scroll_link': 'https://x.com/PGCodeCraft', 'icon': 'bi bi-twitter'},    
        {'id': 'footer6', 'scroll_link': 'https://www.instagram.com/prashantcodecraft', 'icon': 'bi bi-instagram'}
    ];

    return (
        <>
            <footer className="footer">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6 my-2">
                            <div className="nav justify-content-center justify-content-md-start">
                                {
                                    footer_menu.map((val) => {
                                        return <a key={val.id} href={val.scroll_link} target="_blank" rel="noopener noreferrer">
                                            <i className={val.icon}></i>
                                        </a>
                                    })
                                }
                            </div>
                        </div>
                        <div className="col-md-6 my-2 text-center text-md-end">
                            <p>© 2024 copyright <Link to="#">Prashant Gupta</Link></p>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}