export default function About() {
    return (
        <>
            <section id="about" data-scroll-data="1" className={`section about-section gray-bg pb-0`}>
                <div className="text-placeholder">
                    <h5>About</h5>
                </div>
                <div className="container">
                    <div className="row align-items-center flex-row-reverse">
                        <div className="col-lg-7 ps-xl-5">
                            <div className="about-row about-text">
                                <div className="sm-title">
                                    <h3>About Me</h3>
                                </div>
                                <h6>Hello, my name is <span>Prashant Gupta</span>, I am a <span>Full Stack Developer</span> and <span>Solution Architect</span></h6>
                                <br></br>
                                <p>Technical architect with 14+ years of experience in the tech industry. Expertise includes Java (Micronaut, Spring Boot), Angular, React, and Ionic, with a strong focus on building websites, web applications, and leading teams.</p>
                                <p>Specialize in architecting complex solutions using Microservices, Kafka, Docker, and CI/CD pipelines (Jenkins, AWS CodePipeline). Responsible for end-to-end solution design, architecture planning, and overseeing implementation.</p>
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <div className="about-left">
                                <img src="img/Prashant.jpg" alt="about" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
